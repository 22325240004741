class Geocoding {
    constructor() {
        this.geocoder = null;
    }

    getGeocoder() {
        if (!this.geocoder) {
            // eslint-disable-next-line no-undef
            this.geocoder = new google.maps.Geocoder();
        }

        return this.geocoder;
    }

    geocodeAddress(address) {
        return new Promise((resolve, reject) => {
            this.getGeocoder().geocode(
                { address },
                (results, status) => {
                    if(status === 'OK') {
                        const cityComponent = this.getAddressComponent(results, "locality");
                        const stateComponent = this.getAddressComponent(results,"administrative_area_level_1");
                        const countryComponent = this.getAddressComponent(results,"country");

                        resolve({
                            latitude: results[0].geometry.location.lat(),
                            longitude: results[0].geometry.location.lng(),
                            city: cityComponent ? cityComponent.long_name : null,
                            state: stateComponent ? stateComponent.long_name : null,
                            country: countryComponent ? countryComponent.long_name : null
                        });
                    } else
                        reject(status);
                }
            );
        });
    }

    getAddressComponent(geocodeResponse, componentType) {
        return geocodeResponse[0].address_components.find((ac) => {
            return ac.types.some(type => {
                return type === componentType
            })
        });
    }
}

const geocoding = new Geocoding();

export default geocoding;