import {addKeywordInPerson, removeKeywordInPerson, updateKeywordInPerson, updatePersonInState} from './actionHelpers';
import * as actions from "../actions/actionList.js"

export default function updateKeywordsInArray(state, action) {
    let personToUpdate = state.people[action.person_index];
    if(action.type === actions.removeKeyword){
        let person = removeKeywordInPerson(personToUpdate, action.keyword_index);
        return updatePersonInState(state, action.person_index, person);
    }
    else if(action.type === actions.addKeyword){
        let person = addKeywordInPerson(personToUpdate, action.keyword);
        return updatePersonInState(state, action.person_index, person);
    }
    else if(action.type === actions.updateKeyword){
        let person = updateKeywordInPerson(personToUpdate, action.keyword, action.keyword_index)
        return updatePersonInState(state, action.person_index, person);
    }
    else{
        return state;
    }
}