import React, {Component} from 'react';

class Eye extends Component {

    render(){
        if(this.props.visible){
            return(
                <span onClick={this.props.clickHandler}>
                    <i className="fas fa-eye eye"/>
                </span>
            );
        }
        else {
            return (
                <span onClick={this.props.clickHandler}>
                    <i className="fas fa-eye-slash eye"/>
                </span>
            );
        }
    }
}

export default Eye;