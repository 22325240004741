import {updateNameInPerson, updatePersonInState} from "./actionHelpers";
import * as actions from "./actionList";

export default function updateName(state, action){
    let personToUpdate = state.people[action.person_index];
    let nameToUpdate = personToUpdate.name;
    let name = {};
    if(action.type === actions.updateFirstName){
        name = Object.assign({}, nameToUpdate, {
            first: action.first,
        })
    }
    else if(action.type === actions.updateLastName){
        name = Object.assign({}, nameToUpdate, {
            last: action.last,
        })
    }
    else {
        return state;
    }
    let person = updateNameInPerson(personToUpdate, name);
    return updatePersonInState(state, action.person_index, person);
}