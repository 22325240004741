import updatePropertyInLocation from "../actions/updatePropertyInLocation"
import updateName from "../actions/updateName"
import updateVitalStatus from "../actions/updateVitalStatus"
import updateDeathDate from "../actions/updateDeathDate"
import updateKeywordsInArray from "../actions/updateKeywords"
import addPerson from "../actions/addPerson"
import removePerson from "../actions/removePerson"
import updateLocationsArray from "../actions/updateLocationsArray"
import setLoggedIn from '../actions/setLoggedIn';
import toggleVisible from "../actions/toggleVisible";
import toggleCollapsed from "../actions/toggleCollapsed";
import * as actions from "../actions/actionList.js"
import setCurrentUser from "../actions/setCurrentUser";
import setIsAnonymous from "../actions/setIsAnonymous";
import setHasEmailBeenSent from "../actions/setHasEmailBeenSent";
import setHasInvalidEmailBeenSent from "../actions/setHasInvalidEmailBeenSent";
import setIsAdmin from "../actions/setIsAdmin";
import setIsOptedIn from "../actions/setIsOptedIn";
import loadPeopleFromDatabase from "../actions/loadPeopleFromDatabase";
import {makeInitialState} from "../state/testdata";
import resetToDefault from "../actions/resetToDefault";
import setAggregateData from "../actions/setAggregateData";
import setKeywordFilter from "../actions/setKeywordFilter";
import setLocationFilter from "../actions/setLocationFilter";
import setUsersForLookup from "../actions/setUsersForLookup";
import setSelectedUserForLookup from "../actions/setSelectedUserForLookup";
import updateSavedMessage from "../actions/updateSavedMessage";

export default function reducer(state, action) {
    switch(action.type){
        case actions.updateFirstName:
            return updateName(state, action);
        case actions.updateLastName:
            return updateName(state, action);
        case actions.updateVitalStatus:
            return updateVitalStatus(state, action);
        case actions.updateDeathDate:
            return updateDeathDate(state, action);
        case actions.updateStringLocation:
            return updatePropertyInLocation(state, action);
        case actions.updateStart:
            return updatePropertyInLocation(state, action);
        case actions.updateGeocode:
            return updatePropertyInLocation(state, action);
        case actions.addKeyword:
            return updateKeywordsInArray(state, action);
        case actions.removeKeyword:
            return updateKeywordsInArray(state, action);
        case actions.updateKeyword:
            return updateKeywordsInArray(state, action);
        case actions.addPerson:
            return addPerson(state, action);
        case actions.removePerson:
            return removePerson(state, action);
        case actions.addLocation:
            return updateLocationsArray(state, action);
        case actions.removeLocation:
            return updateLocationsArray(state, action);
        case actions.togglePersonCollapsed:
            return toggleCollapsed(state, action);
        case actions.togglePersonVisible:
            return toggleVisible(state, action);
        case actions.setLoggedIn:
            return setLoggedIn(state, action);
        case actions.setCurrentUser:
            return setCurrentUser(state, action);
        case actions.setIsAnonymous:
            return setIsAnonymous(state, action);
        case actions.setHasEmailBeenSent:
            return setHasEmailBeenSent(state, action);
        case actions.setHasInvalidEmailBeenSent:
            return setHasInvalidEmailBeenSent(state, action);
        case actions.setIsAdmin:
            return setIsAdmin(state, action);
        case actions.setIsOptedIn:
            return setIsOptedIn(state, action);
        case actions.loadPeopleFromDatabase:
            return loadPeopleFromDatabase(state, action);
        case actions.setAggregateData:
            return setAggregateData(state, action);
        case actions.setKeywordFilter:
            return setKeywordFilter(state, action);
        case actions.setLocationFilter:
            return setLocationFilter(state, action);
        case actions.setUsersForLookup:
            return setUsersForLookup(state, action);
        case actions.setSelectedUserForLookup:
            return setSelectedUserForLookup(state, action);
        case actions.updateSavedMessage:
            return updateSavedMessage(state, action);
        case actions.resetToDefault:
            return resetToDefault(state, action);
        default:
            return makeInitialState();
    }
}