export function updateNameInPerson(person, newName) {
    return Object.assign({}, person, {
       name: newName
    });
}

export function addKeywordInPerson(person, keyword) {
    const newKeywords = insertItemAtEnd(person.keywords, keyword);
    return Object.assign({}, person, {
        keywords: newKeywords
    });
}

export function removeKeywordInPerson(person, keyword_index) {
    const newKeywords = removeItemAtIndex(person.keywords, keyword_index);
    return Object.assign({}, person, {
        keywords: newKeywords
    });
}

export function updateKeywordInPerson(person, keyword, keyword_index) {
    const newKeywords = updateItemAtIndex(person.keywords, keyword, keyword_index);
    return Object.assign({}, person, {
        keywords: newKeywords
    });
}

export function updateLocationInPerson(person, location_index, location) {
    const newLocations = updateObjectInArray(person.locations, location_index, location);
    return Object.assign({}, person, {
        locations: newLocations
    });
}

export function updatePersonInState(state, person_index, person){
    const people = updateObjectInArray(state.people, person_index, person);
    return Object.assign({}, state, {
        people
    });
}

export function loadPeopleFromDatabase(state, database){
    const data = database.ref().child('testdata');
    const peopleData = data.child('people');
    peopleData.on('value', snap => {
        const people = snap.val()
        return Object.assign({}, state, {
            people
        });
    });
}

/* Redux util functions */
export function updateObjectInArray(array, index, newObject) {
    return array.map((element, i) => {
        if (i !== index) {
            return element;
        }
        return {
            ...element,
            ...newObject
        };
    });
}


export function insertItemAtEnd(array, item) {
    if(!array)
        array = [];
    let newArray = array.slice();
    newArray.push(item);
    return newArray;
}
export function insertItemAtIndex(array, index_to_insert, item) {
    let newArray = array.slice();
    newArray.splice(index_to_insert, 0, item);
    return newArray;
}
export function removeItemAtIndex(array, index_to_remove) {
    let newArray = array.slice();
    newArray.splice(index_to_remove, 1);
    return newArray;
}
export function updateItemAtIndex(array, new_value, index_to_update) {
    let newArray = array.slice();
    newArray[index_to_update] = new_value;
    return newArray;
}