import React, {Component} from 'react';

import Container from 'react-bootstrap/Container';

//import Row from 'react-bootstrap/Row';

class Privacy extends Component {
    render() {
        return (
            <div>
                <Container>
                    <h4>Privacy</h4>

                    <p>
                        This privacy policy sets out how Timeline Atlas uses and protects any information that you give
                        Timeline Atlas when you use this website. Timeline Atlas collects, processes and stores your
                        personal information as you use our website and services. Personal information is information
                        that can identify you, such as your name, email or address, or it may be information that could
                        reasonably be linked back to you. Timeline Atlas is committed to ensuring that your privacy is
                        protected. Should we ask you to provide certain information by which you can be identified when
                        using this website, then you can be assured that it will only be used in accordance with this
                        privacy statement. Timeline Atlas may change this policy from time to time by updating this
                        page. You should check this page from time to time to ensure that you are happy with any
                        changes. This policy is effective from April 1, 2019.
                    </p>

                    <h6>What we collect</h6>
                    <p>We may collect the following information:</p>
                    <ul>
                        <li>Name</li>
                        <li>Email address</li>
                        <li>Locations and dates of birth and moves (user provided content)</li>
                        <li>Locations and dates of birth and moves for other people (user provided content)</li>
                        <li>Information about deceased persons is not personal information under this Privacy
                            Statement
                        </li>
                        <li>If you use Facebook to log into Timeline Atlas, we collect information from your Facebook
                            profile that you choose to provide us
                        </li>
                        <li>The Internet Protocol (IP) address of your computer, mobile device, or the proxy server that
                            you use to access the internet, in addition to other technical information, such as: your
                            computer operating system, web browser, mobile device identifier provided by your mobile
                            device operating system, and the name of your internet service provider or mobile carrier.
                        </li>
                        <li>Information from your use of Timeline Atlas services such as when you add, search, or filter
                            data.
                        </li>
                    </ul>

                    <h6>What we do with the information we gather</h6>
                    <p> We require this information to understand your needs and provide you with a better service, and
                        in particular for the following reasons:</p>
                    <ul>
                        <li>Creating dynamic online visualizations that are viewable by you and other Timeline Atlas
                            account holders, and may be viewable to non-account holders visiting the site. Your data
                            will be anonymized, but may show a line that represents the dates and locations of births
                            and moves you enter into the site.
                        </li>
                        <li>Developing personalized products</li>
                        <li>Authenticating your access to Timeline Atlas</li>
                        <li>Internal record keeping</li>
                        <li>We may use the information to improve our products and services</li>
                        <li>We may periodically send promotional email about new products, special offers or other
                            information which we think you may find interesting using the email address which you have
                            provided
                        </li>
                        <li>We may use the information to customize the website according to your interests.</li>
                        <li>Conduct scientific, statistical, and historical research</li>
                        <li>Detecting and protecting against error, fraud, or other criminal or malicious activity and
                            enforcing our Terms and Conditions.
                        </li>
                        <li>We will never sell your information.</li>
                    </ul>

                    <h6>Aggregated Data</h6>
                    <p>Timeline Atlas may disclose user information in an aggregated form as part of services,
                        marketing, or publications. Such disclosures will never include personal information.</p>

                    <h6>Security </h6>
                    <p>We are committed to ensuring that your information is secure. In order to prevent unauthorized
                        access or disclosure we have put in place suitable physical, electronic and managerial
                        procedures to safeguard and secure the information we collect online.</p>

                    <h6>How we use cookies </h6>
                    <p>A cookie is a small file which asks permission to be placed on your computer's hard drive. Once
                        you agree, the file is added and the cookie helps analyze web traffic or lets you know when you
                        visit a particular site. Cookies allow web applications to respond to you as an individual. The
                        web application can tailor its operations to your needs, likes and dislikes by gathering and
                        remembering information about your preferences. We use traffic log cookies to identify which
                        pages are being used. This helps us analyze data about web page traffic and improve our website
                        in order to tailor it to customer needs. We only use this information for statistical analysis
                        purposes and then the data is removed from the system. Overall, cookies help us provide you with
                        a better website, by enabling us to monitor which pages you find useful and which you do not. A
                        cookie in no way gives us access to your computer or any information about you, other than the
                        data you choose to share with us. You can choose to accept or decline cookies. Most web browsers
                        automatically accept cookies, but you can usually modify your browser setting to decline cookies
                        if you prefer. This may prevent you from taking full advantage of the website.</p>


                    <h6>Links to other websites</h6>
                    <p>Our website may contain links to enable you to visit other websites of
                        interest easily. However, once you have used these links to leave our site, you should note that
                        we do not have any control over that other website. Therefore, we cannot be responsible for the
                        protection and privacy of any information which you provide whilst visiting such sites and such
                        sites are not governed by this privacy statement. You should exercise caution and look at the
                        privacy statement applicable to the website in question.</p>

                    <h6>Account</h6>
                    <p>Timeline Atlas will retain the personal information you provide while creating your account until
                        such time as you close it.</p>

                    <h6>Controlling your personal information </h6>
                    <p>You may choose to restrict the collection or use of your personal information in the following
                        ways:</p>
                    <ul>
                        <li>whenever you are asked to fill in a form on the website, look for the box that you can click
                            to indicate that you do not want the information to be used by anybody for direct marketing
                            purposes
                        </li>
                        <li>if you have previously agreed to us using your personal information for direct marketing
                            purposes, you may change your mind at any time by writing to or emailing us at
                            timelineatlas.gmail.com
                        </li>
                    </ul>
                    We will not sell, distribute or lease your personal information to third parties unless we have your
                    permission or are required by law. We may use your personal information to send you promotional
                    information about third parties which we think you may find interesting if you tell us that you wish
                    this to happen. You may request details of personal information which we hold about you under the
                    Data Protection Act 1998. <strong>A small fee will be payable</strong>. If you would like a copy of
                    the information held on you please write to timelineatlas.gmail.com. If you believe that any
                    information we are holding on you is incorrect or incomplete, please write to or email us as soon as
                    possible, at the above address. We will promptly correct any information found to be incorrect.

                </Container>
            </div>
        );
    }
}

export default Privacy;