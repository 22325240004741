import * as actions from "./actionList";
import {updatePersonInState} from "./actionHelpers";

export default function toggleCollapsed(state, action) {
    if(action.type === actions.togglePersonCollapsed){
        let personToUpdate = state.people[action.person_index];
        let isCollapsed = personToUpdate.collapsed;
        let person = Object.assign({}, personToUpdate, {
            collapsed: !isCollapsed
        });
        return updatePersonInState(state, action.person_index, person);
    }
}