import {updateLocationInPerson, updatePersonInState} from './actionHelpers';
import * as actions from "./actionList";

export default function updatePropertyInLocation(state, action) {
    let personToUpdate = state.people[action.person_index];
    let locations = personToUpdate.locations;
    let locationToUpdate = locations[action.location_index];

    let location = {};
    if(action.type === actions.updateStart){
        location = Object.assign({}, locationToUpdate, {
            start: action.start,
        });
    }
    else if(action.type === actions.updateStringLocation){
        location = Object.assign({}, locationToUpdate, {
            stringLocation: action.stringLocation
        });
    }
    else if(action.type === actions.updateGeocode){
        location = Object.assign({}, locationToUpdate, {
            latitude: action.latitude,
            longitude: action.longitude,
            city: action.city,
            state: action.state,
            country: action.country
        });
    }
    else{
        return state;
    }

    const person = updateLocationInPerson(personToUpdate, action.location_index, location);
    return updatePersonInState(state, action.person_index, person);
}

