import React, {Component} from 'react';
import ListGroup from 'react-bootstrap/ListGroup'
import ListGroupItem from "react-bootstrap/ListGroupItem";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {Link} from "react-router-dom";

class About extends Component {
    render() {
        return (
            <div>
                <Container>
                    <p>
                        Timeline Atlas is a public interactive artwork that examines the movement of people over time.
                        Individual lines accumulate into a sprawling network of people’s trajectories through life.
                        Participants can view their timelines online and create objects and prints of their trajectory through time.
                    </p>
                    <p>
                        In order to participate:
                    </p>
                        <ListGroup className="mt-3 mb-3">
                            <ListGroupItem className="aboutListItem">
                                <strong>Input</strong> some information about where you have lived and when you have moved
                            </ListGroupItem>
                            <ListGroupItem className="aboutListItem">
                                <strong>See</strong> the results of your path through time and space in a dynamic 3D visualization
                            </ListGroupItem>
                            <ListGroupItem className="aboutListItem">
                                <strong>Save</strong> your line to the growing database
                            </ListGroupItem>
                            <ListGroupItem className="aboutListItem">
                                <strong>Search and filter</strong> to compare your timeline to others
                            </ListGroupItem>
                        </ListGroup>

                    <i>Timeline Atlas was conceived by Stephen Cartwright as an extension of his <a href="http://www.stephencartwright.com/#/life-location-project/" rel="noopener noreferrer" target="_blank">self-tracking art projects.</a></i>

                    <Row className="mt-4">
                            <Link to="/privacy" className="infoLink">Privacy</Link> -
                            <Link to="/terms" className="infoLink">Terms of Use</Link> -
                            <Link to="/community-guidelines" className="infoLink">Community Guidelines</Link>
                    </Row>
                </Container>
            </div>
        );
    }
}
export default About;