import {makeBlankLocation} from "../state/testdata";
import {insertItemAtEnd, removeItemAtIndex, updatePersonInState} from "./actionHelpers";
import * as actions from "./actionList";

export default function updateLocationsArray(state, action) {
    let personToUpdate = state.people[action.person_index];
    let locationsToUpdate = personToUpdate.locations;
    let newLocations = [];
    if(action.type === actions.addLocation){
        newLocations = insertItemAtEnd(locationsToUpdate, makeBlankLocation());
    }
    else if(action.type === actions.removeLocation){
        newLocations = removeItemAtIndex(locationsToUpdate, action.location_index);
    }
    else {
        return state;
    }
    let newPerson = Object.assign({}, personToUpdate, {
        locations: newLocations
    });
    return updatePersonInState(state, action.person_index, newPerson);
}