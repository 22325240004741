import * as actions from "./actionList";
import {updatePersonInState} from "./actionHelpers";

export default function toggleVisible(state, action) {
    if(action.type === actions.togglePersonVisible){
        let personToUpdate = state.people[action.person_index];
        let isVisible = personToUpdate.visible;
        let person = Object.assign({}, personToUpdate, {
            visible: !isVisible
        });
        return updatePersonInState(state, action.person_index, person);
    }
}