import {makeBlankPerson} from "../state/testdata";
import {insertItemAtEnd} from "./actionHelpers";
// import database from "../persistence/database";

export default function addPerson(state, action) {
    let collapsedPeople = collapseAllPeople(state.people);
    let newPeople = insertItemAtEnd(collapsedPeople, makeBlankPerson());
    // const currentUser = state.currentUser;
    // const data = database.ref().child('users').currentUser.people;
    //
    // data.on('value', snap => {
    //     if (!state.isAnonymous) {
    //         data.push(state.people);
    //     }
    // });
    // console.log(state);
    return {
        ...state,
        people: newPeople
    }
}

function collapseAllPeople(people){
    let newPeople = people.slice();
    newPeople.forEach(function(person, index){
        let newPerson = collapsePerson(person);
        newPeople[index] = newPerson;
    });
    return newPeople;
}

export function collapsePerson(person) {
    return Object.assign({}, person, {
        collapsed: true,
    });
}