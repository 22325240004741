import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Map, TileLayer, Polyline, CircleMarker} from "react-leaflet";
import * as mapService from '../services/mapService';

const BOUNDS_OPTIONS = {
    padding: [50, 50]
};
const MAP_HEIGHT = 800;
const MIN_ZOOM = 1;
export const DEFAULT_BOUNDS = [
    [40.145097, -88.312733],
    [40.057095, -88.172596]
];

function mapStateToProps(state) {
    const polylines = mapService.get2DLinesWithColor(state.people);
    const bounds = mapService.getBounds(polylines);
    return {polylines, bounds};
}

export class TimelineMap extends Component {
    render() {
        return (
            <div>
                <link rel="stylesheet" href="https://unpkg.com/leaflet@1.4.0/dist/leaflet.css"
                      integrity="sha512-puBpdR0798OZvTTbP4A8Ix/l+A4dHDD0DGqYW6RQ+9jxkRFclaxxQb/SJAWZfWAkuyeQUytO7+7N4QKrDh+drA=="
                      crossOrigin=""/>
                <Map ref="timeline-map" bounds={this.props.bounds} boundsOptions={BOUNDS_OPTIONS} style={{height: MAP_HEIGHT}} minZoom={MIN_ZOOM}>
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                    {this.props.polylines.map((polyline, index) => {
                        return (
                            <Polyline key={`map-line-${index}`} color={polyline.color} positions={polyline.positions} weight={2}/>
                        );
                    })}
                    {this.props.polylines.map((polyline, personIndex) => {
                        return polyline.positions.map((point, pointIndex) => {
                            return (
                                <CircleMarker key={`map-marker-${personIndex}-${pointIndex}`}
                                              color={polyline.color} center={point} weight={0}/>
                            );
                        });
                    })}
                </Map>
                <script src="https://unpkg.com/leaflet@1.4.0/dist/leaflet.js"
                        integrity="sha512-QVftwZFqvtRNi0ZyCtsznlKSWOStnDORoefr1enyq5mVL4tmKB3S/EnC3rRJcxCPavG10IcrVGSmPh6Qw5lwrg=="
                        crossOrigin=""/>
            </div>
        );
    }



}

export default connect(
    mapStateToProps,
)(TimelineMap);