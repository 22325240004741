export function makeInitialState() {
    return {
        currentUser: null,
        isAdmin: null,
        isAnonymous: null,
        people: [
            makeBlankPerson(),
        ]
    }
}

export function makeBlankPeopleArray() {
    return {
        people: [
            makeBlankPerson(),
        ]
    }
}
export function makeBlankPerson() {
    return {
        name: {
            first: '',
            last: '',
        },
        keywords: [],
        deceased: false,
        deathDate: null,
        visible: true,
        collapsed: false,
        locations: [
            makeBlankLocation(),
        ]
    }
}

export function makeInvisiblePerson() {
    return {
        name: {
            first: '',
            last: '',
        },
        keywords: [],
        deceased: false,
        deathDate: null,
        visible: false,
        collapsed: false,
        locations: [
            makeBlankLocation(),
        ]
    }
}

export function makeCollapsedPerson() {
    return {
        name: {
            first: '',
            last: '',
        },
        keywords: [],
        deceased: false,
        deathDate: null,
        visible: true,
        collapsed: true,
        locations: [
            makeBlankLocation(),
        ]
    }
}


export function makeBlankLocation() {
    return {
        stringLocation: '',
        start: null,
        latitude: null,
        longitude: null,
        city: '',
        state: '',
        country: '',
    }
}

export function makeInitialStateWithTwoPeople() {
    return {
        people: [
            makeBlankPerson(),
            makeBlankPerson()
        ]
    }
}

export function makeFinalStateWithTwoPeople() {
    return {
        people: [
            {
                name: {
                    first: 'Jay',
                    last: 'Gatsby',
                },
                keywords: [],
                deceased: false,
                visible: true,
                locations: [
                    {
                        start: new Date('1/1/2000'),
                        latitude: 41.878113,
                        longitude: -87.629799,
                        city: 'Chicago',
                        state: 'Illinois',
                        country: 'USA',
                        stringLocation: 'Chicago'
                    },
                    {
                        start: new Date('1/1/2015'),
                        latitude: 43.000204,
                        longitude: -89.569907,
                        city: 'Madison',
                        state: 'Wisconsin',
                        country: 'USA',
                        stringLocation: 'Epic Madison Wisconsin'
                    },
                    {
                        start: new Date('1/1/2019'),
                        latitude: 40.1127829,
                        longitude: -88.2083838,
                        city: 'Urbana',
                        state: 'Illinois',
                        country: 'USA',
                        stringLocation: '109 Goose Alley, Urbana, Illinois 61801, United States'
                    }
                ],
            },
            {
                name: {
                    first: 'Daisy',
                    last: 'Buchanan',
                },
                keywords: [],
                deceased: false,
                visible: true,
                locations: [
                    {
                        start: new Date('1/1/1985'),
                        latitude: 37.83,
                        longitude: -84,
                        city: '',
                        state: 'Kentucky',
                        country: 'USA',
                        stringLocation: 'Kentucky'
                    },
                    {
                        start: new Date('1/1/2001'),
                        latitude: 38.631809,
                        longitude: -90.328011,
                        city: 'Chicago',
                        state: 'Illinois',
                        country: 'USA',
                        stringLocation: 'St Louis Arch'
                    },
                    {
                        start: new Date('1/1/2019'),
                        latitude: 40.1127829,
                        longitude: -88.2083838,
                        city: 'Urbana',
                        state: 'Illinois',
                        country: 'USA',
                        stringLocation: '109 Goose Alley, Urbana, Illinois 61801, United States'
                    }
                ],
            }
        ]
    }
}

export function makePixo() {
    return {
        start: null,
        latitude: null,
        longitude: null,
        city: '',
        state: '',
        country: '',
        stringLocation: '109 Goose Alley, Urbana, Illinois 61801, United States'
    }
}

export function makePixoGeocode() {
    return {
        start: null,
        latitude: 40.1127829,
        longitude: -88.2083838,
        city: 'Urbana',
        state: 'Illinois',
        country: 'USA',
        stringLocation: ''
    }
}

export function makeChicago() {
    return {
        start: null,
        latitude: null,
        longitude: null,
        city: '',
        state: '',
        country: '',
        stringLocation: 'Chicago'
    }
}
