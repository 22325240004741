import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import React, {Component} from "react";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {};
}

class TypeaheadFilter extends Component {
    render() {
        const options = Object.keys(this.props.options).map(key => {
            return {
                name: `${key} (${this.props.options[key]})`,
                value: key
            };
        });

        return <div>
            <Typeahead
                id={this.props.id}
                labelKey="name"
                multiple={false}
                options={options}
                placeholder={this.props.placeholder}
                onChange={event => this.selectItem(event)}
            />
        </div>;
    }

    selectItem(event) {
        if(event.length > 0)
            this.props.callback(event[0].value);
        else
            this.props.callback(null);
    }
}

export default connect(
    mapStateToProps,
)(TypeaheadFilter);