import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import React, {Component} from 'react';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {};
}

class HelpTextIcon extends Component {
    render() {
        return <OverlayTrigger

            placement={"right"}
            overlay={
                <Tooltip>
                    {this.props.message}
                </Tooltip>
            }>
            <i className="fa fa-question-circle"/>
        </OverlayTrigger>;
    }
}

export default connect(
    mapStateToProps,
)(HelpTextIcon);