import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import AggregateGraph from "../components/AggregateGraph";
import TypeaheadFilter from "../components/TypeaheadFilter"

import * as actions from '../actions/actionList';

function mapStateToProps(state) {
    if(state.aggregateData)
        return {
            keywords: state.aggregateData.keywordsCount,
            locations: state.aggregateData.locationsCount
        };
    else
        return {
            keywords: [],
            locations: []
        };
}

class Home extends Component {
    render() {
        return (
            <div>
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-sm-3 mt-5">
                            <p>Timeline Atlas is an <strong>art and mapping project</strong> that displays
                                three-dimensional models of people's trajectories through time and space.</p>

                            <p>See how your trajectory through life compares with others and get a better understanding
                                of the movement of people over time.</p>

                            <p>Enter some location and time information of events from your life (birth, moves, etc.) to
                                see your line.</p>
                            <p><Link to="/timeline">Add your Timeline here.</Link></p>
                        </div>
                        <div className="col-sm-6">
                            <AggregateGraph/>
                        </div>
                        <div className="col-sm-3 mt-5">
                            <p>Filter by keyword</p>
                            <TypeaheadFilter
                                options={this.props.keywords}
                                placeholder="Filter by keyword"
                                id='keyword-filter'
                                callback={(event) => this.updateKeywordFilter(event)}/>
                            <p className='mt-3'>Filter by location</p>
                            <TypeaheadFilter
                                options={this.props.locations}
                                placeholder="Filter by location"
                                id='location-filter'
                                callback={(event) => this.updateLocationFilter(event)}/>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    updateKeywordFilter(event) {
        this.props.dispatch({
            type: actions.setKeywordFilter,
            keywordFilter: event
        });
    }

    updateLocationFilter(event) {
        this.props.dispatch({
            type: actions.setLocationFilter,
            locationFilter: event
        });
    }
}

export default connect(
    mapStateToProps,
)(Home);