import React, {Component} from 'react';
import {connect} from 'react-redux';
import Plot from 'react-plotly.js';
import * as graphService from '../services/graphService';

function mapStateToProps(state) {
    const peopleTrace = state.people
        .map((person, index) => {
            return graphService.get3DLineForPerson(person, index);
        });
    return { peopleTrace };
}

class TimelineGraph extends Component {
    render() {
        const layoutConfig = graphService.getLayoutConfig();
        const configConfig = graphService.getPlotlyConfig();
        return (
            <div>
                <Plot
                    data={this.props.peopleTrace}
                    layout={layoutConfig}
                    config={configConfig}
                />
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(TimelineGraph);