export const updateFirstName = "UPDATE_FIRST_NAME";
export const updateLastName = "UPDATE_LAST_NAME";
export const updateKeyword = "UPDATE_KEYWORD";
export const updateStringLocation =  "UPDATE_STRING_LOCATION";
export const updateStart =  "UPDATE_START";
export const updateGeocode =  "UPDATE_GEOCODE";
export const updateVitalStatus = "UPDATE_VITALS";
export const addKeyword = "ADD_KEYWORDS";
export const removeKeyword = "REMOVE_KEYWORDS";
export const addPerson =  "ADD_PERSON";
export const removePerson =  "REMOVE_PERSON";
export const addLocation =  "ADD_LOCATION";

export const removeLocation =  "REMOVE_LOCATION";
export const setLoggedIn = "SET_LOGGED_IN";
export const updateDeathDate = "UPDATE_DEATH_DATE";
export const togglePersonVisible = "TOGGLE_PERSON_VISIBLE";
export const togglePersonCollapsed = "TOGGLE_PERSON_COLLAPSED";

export const setCurrentUser = "SET_CURRENT_USER";
export const setIsAnonymous = "SET_IS_ANONYMOUS";
export const setHasEmailBeenSent = "SET_HAS_EMAIL_BEEN_SENT";
export const setHasInvalidEmailBeenSent = "SET_HAS_INVALID_EMAIL_BEEN_SENT";
export const setIsAdmin = "SET_IS_ADMIN";
export const setIsOptedIn = "SET_IS_OPTED_IN";
export const loadPeopleFromDatabase = "LOAD_PEOPLE_FROM_DATABASE";

export const setAggregateData = "SET_AGGREGATE_DATA";
export const setKeywordFilter = "SET_KEYWORD_FILTER";
export const setLocationFilter = "SET_LOCATION_FILTER";

export const setUsersForLookup = "SET_USERS_FOR_LOOKUP";
export const setSelectedUserForLookup = "SET_SELECTED_USER_FOR_LOOKUP";

export const updateSavedMessage = "UPDATE_SAVED_MESSAGE";

export const resetToDefault = "RESET_TO_DEFAULT";
