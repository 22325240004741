import {currentDateWithoutTimezone} from "../constants";
import utils from "./utils";

export function isValidPoint(location) {
    return !(utils.isBlank(location.latitude) ||
        utils.isBlank(location.longitude) ||
        utils.isBlank(location.start));

}

export function get3DLineForPerson(person, index) {
    const baseTrace = getBaseTraceConfig();
    let trace = setCustomPropsOfBase(baseTrace, person, index);
    let points = get3DPointsWithCity(person);


    trace["x"] = points.x;
    trace["y"] = points.y;
    trace["z"] = points.z;
    trace["text"] = points.text;

    return trace;
}

export function get3DPointsWithCity(person) {
    let points = {
        x: [],
        y: [],
        z: [],
        text: [],
    };
    utils.sortValidLocationsByDate(person.locations).forEach(function (location, index, array) {
        const city = `${location.city || 'Unknown City'}`;
        const isNotFirstPoint = (index !== 0);
        if (isNotFirstPoint) {
            const previousPoint = array[index - 1];
            addPointsToArrays(points, previousPoint.longitude, previousPoint.latitude, getDateForGraph(location.start), city);
        }
        addPointsToArrays(points, location.longitude, location.latitude, getDateForGraph(location.start), city);
        let isLastPoint = (index === array.length - 1);
        if (isLastPoint) {
            if (person.deceased && person.deathDate) {
                addPointsToArrays(points, location.longitude, location.latitude, getDateForGraph(person.deathDate), city);
            } else {
                addPointsToArrays(points, location.longitude, location.latitude, getDateForGraph(currentDateWithoutTimezone), city);
            }
        }
    });
    return points;
}

function getDateForGraph(dateString) {
    const date = new Date(dateString);
    const dateWithoutTimezone = utils.getDateWithoutTimezone(date);
    return utils.formatDateToString(dateWithoutTimezone);
}

function setCustomPropsOfBase(baseTrace, person, index,) {
    return Object.assign(baseTrace, {
        line: {
            color: utils.getLineColor(index),
        },
        name: (person.name) ? person.name.first : null,
        visible: person.visible,
        hoverlabel: {
            bgcolor: utils.getLineColor(index),
            bordercolor: 'white',
            font: {
                family: ['Arial', 'sans-serif'],
            },
        },
    });

}

function addPointsToArrays(points, x_point, y_point, z_point, text_point) {
    points.x.push(x_point);
    points.y.push(y_point);
    points.z.push(z_point);
    points.text.push(text_point);
}

export function getBaseTraceConfig() {
    return {
        type: 'scatter3d',
        mode: 'lines+markers',
        opacity: 1,
        line: {
            width: 3,
        },
        marker: {
            size: 1
        },
        hoverinfo: 'text+z',

    };
}

export function getPlotlyConfig() {
    return {
        modeBarButtons: [
            ['zoom3d', 'pan3d', 'tableRotation', 'resetCameraLastSave3d']
        ],
        displaylogo: false,
    }
}

export function getLayoutConfig() {
    return {
        height: 700,
        width: 500,
        title: {
            text: '',
            font: {
                size: 1,
            }
        },
        /*legend: {traceToggle: false, }*/
        //TODO disable ability to toggle visibility through legend. Feature should be in latest release https://github.com/plotly/plotly.js/issues/665
        margin: {
            l: 0,
            r: 0,
            b: 0,
            t: 0,
            //pad: 500000
            //TODO investigate why plotly margin is not updating https://plot.ly/javascript/reference/#layout-margin
            autoexpand: false,
        },
        scene: {
            xaxis: {
                title: {
                    text: 'longitude'
                }
            },
            yaxis: {
                title: {
                    text: 'latitude'
                }
            },
            zaxis: {
                title: {
                    text: 'date'
                }
            },
            camera: {
                eye: {
                    x: -1.5,
                    y: -3,
                    z: 0.5
                }
            },
            aspectmode: 'manual',
            aspectratio: {
                x: 1,
                y: 1,
                z: 2
            }
        },
    }
}