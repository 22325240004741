import * as firebase from 'firebase';
import store from '../state/store';
import utils from '../services/utils';
import * as actions from '../actions/actionList';

class EmailLinkAuthentication {
    checkForPendingRedirect() {

        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                utils.setCurrentUser(user);
                utils.setIsAnonymous(user.isAnonymous);
            }
            else {
                utils.setIsAnonymous(true);
            }

            utils.updateUserData();
        });

        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            var email = this.getEmail();
            if (!email) {
                email = window.prompt('Please provide your email for confirmation');
            }
            firebase.auth().signInWithEmailLink(email, window.location.href)
                .then((result) => {
                    window.localStorage.removeItem('emailForSignIn');
                    utils.clearAnonymousPeople();
                    this.successfulLogIn();
                });
        }
    }


    getEmail() {
        return window.localStorage.getItem('emailForSignIn');
    }

    setEmail(email) {
        window.localStorage.setItem('emailForSignIn', email);
    }

    startLogIn(email) {
        firebase.auth().sendSignInLinkToEmail(email, this.getActionCodeSettings())
            .then(() => this.setEmail(email))
            .then(() => this.successfulLogIn())
    }

    successfulLogIn() {
        store.dispatch({
            type: actions.setLoggedIn,
            email: this.getEmail()
        });
    }

    getActionCodeSettings() {
        return {
            handleCodeInApp: true,
            url: window.location.href
        };
    }
}

const emailLinkAuthentication = new EmailLinkAuthentication();

export default emailLinkAuthentication;