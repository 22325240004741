import React, {Component} from 'react';
import {connect} from 'react-redux';
import Person from '../components/person';
import TimelineMap from "../components/map";
import store from '../state/store';

import * as actions from '../actions/actionList';
import TimelineGraph from "../components/graph";
import Button from "react-bootstrap/Button";
import utils from "../services/utils";
import {makeInitialState} from "../state/testdata";

function mapStateToProps(state) {
    if (state.isAnonymous && state.people && utils.stateHasValidPeople(state))
        utils.storeAnonymousPeople(state.people);

    return {
        currentUser: state.currentUser,
        currentUserEmail: state.currentUserEmail,
        isAdmin: state.isAdmin,
        isAnonymous: state.isAnonymous,
        isLoggedIn: !state.isAnonymous,
        isOptedIn: state.isOptedIn,
        people: state.people || makeInitialState().people,
        savedMessageVisibility: state.savedMessageVisibility || false
    };
}

class Timeline extends Component {
    constructor(props) {
        super(props);
        this.addPerson = this.addPerson.bind(this);
    }

    componentDidMount() {
        const state = store.getState();
        mapStateToProps(state);
    }

    renderSaveButton() {
        if (this.props.isLoggedIn) {
            const savedMessageClassBase = 'ml-3 saved-message ';
            const savedMessageClassVariable = this.props.savedMessageVisibility ? 'saved-message--visible' : 'saved-message--hidden'
            return (
                <div className="save-button-container">
                    <Button className="timeline__save-button" variant="outline-dark" size="sm"
                            onClick={() => utils.pushUserData()}>
                        Save Timelines
                    </Button>
                    <div className={savedMessageClassBase + savedMessageClassVariable}>
                        Your data has been saved.
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                <p className={this.props.isLoggedIn === true ? "info__logged-in" : "info__not-logged-in"}>
                    Create a
                    <button className="button-link"
                            type="button"
                            data-toggle="modal"
                            data-target="#authModal">
                        <strong>login</strong>
                    </button>
                    to save the data that powers your trajectory and use it later. You are welcome to use the tool
                    without a login, but your data will not be accessible later.
                </p>
                <p className={this.props.isLoggedIn === true ? "share__logged-in" : "share__not-logged-in"}>
                    <input type="checkbox" name="opt-in" className="opt-in-checkbox"
                           checked={this.props.isOptedIn ? "checked" : null}
                           onChange={evt => this.updateOptInValue(evt)}/>
                    <label>Yes, I want to share my data anonymously, so that I can see how my trajectory compares with
                        others.</label>
                </p>
                <div className="row align-items-start no-gutters">
                    <div className="col-sm-2">
                        {this.renderSaveButton()}
                        <div>
                            {this.props.people.map((person, index) => {
                                return (<Person key={index} person={person} index={index}/>)
                            })}
                        </div>
                        <div className="button-container">
                            <button className="btn btn-outline-dark" onClick={this.addPerson}>Add Person</button>
                            {this.renderSaveButton()}
                        </div>
                    </div>
                    <div className="col-sm-4 map-container">
                        <TimelineMap/>
                    </div>
                    <div className="col-sm-4 graph-container">
                        <TimelineGraph/>
                    </div>
                </div>
                <div className="mt-3">
                    <h4>Our online store</h4>
                    <p>
                        Purchase prints or laser-etched sculptures of your timeline
                        <a href="https://timelineatlas.bigcartel.com/" rel="noopener noreferrer" target="_blank"> here</a>.
                        Please save your timelines before navigating off this page.</p>
                </div>
            </div>
        );
    }

    addPerson() {
        this.props.dispatch({
            type: actions.addPerson
        })
    }

    updateOptInValue(evt) {
        let optedIn = evt.currentTarget.checked;

        store.dispatch({
            type: actions.setIsOptedIn,
            isOptedIn: optedIn
        });
    }
}

export default connect(
    mapStateToProps,
)(Timeline);