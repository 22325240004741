import React, {Component} from 'react';
import {connect} from "react-redux";
import store from "../state/store";
import * as actions from "../actions/actionList";
import utils from "../services/utils";
import {Typeahead} from "react-bootstrap-typeahead";
import config from '../firebase-auth.json';

function mapStateToProps(state) {
    const usersForLookup = state.usersForLookup ? state.usersForLookup : {};
    const options = [];
    Object.keys(usersForLookup).forEach(key => {
        const user = usersForLookup[key];
        if(user.email) {
            options.push({
                email: user.email,
                uuid: key
            })
        }
    });

    if(state.isAdmin && options.length === 0)
        utils.loadAllUsersForLookup();

    return {
        isAdmin: state.isAdmin,
        usersForLookup,
        options,
        selectedUuidForLookup: state.selectedUuidForLookup
    };
}

class UserLookup extends Component {
    componentDidMount() {
        const state = store.getState();
        mapStateToProps(state);
    }

    render() {
        if(this.props.isAdmin)
            return (
                <div>
                    <h4 className="mt-3 mb-4">User Lookup</h4>

                    <div className="row align-items-start no-gutters">
                        <div className="col-sm-3 mr-3">
                            <Typeahead
                                id="user-lookup"
                                labelKey="email"
                                multiple={false}
                                options={this.props.options}
                                placeholder="Enter an email"
                                onChange={event => this.lookUpUser(event)}
                            />
                        </div>
                        <div className="col-sm-6">
                            {this.renderUserDetails()}
                        </div>
                    </div>
                </div>
            );
        else
            return (
                <div>
                    You must have admin rights to see this page.
                </div>
            );
    }

    lookUpUser(event) {
        const selectedUuid = event.length > 0 ? event[0].uuid : null;

        this.props.dispatch({
            type: actions.setSelectedUserForLookup,
            uuid: selectedUuid
        })
    }

    renderUserDetails() {
        if(this.props.selectedUuidForLookup) {
            const user = this.props.usersForLookup[this.props.selectedUuidForLookup];

            return (
                <div>
                    <p>Email: {user.email}</p>
                    <a href={config.databaseURL + '/users/' + this.props.selectedUuidForLookup}>See Data on Firebase</a>
                    <pre>
                        {JSON.stringify(user, null, 4)}
                    </pre>
                </div>
            );
        } else {
            return (
                <div>
                    Select a user to see their details.
                </div>
            );
        }
    }
}

export default connect(
    mapStateToProps,
)(UserLookup);