import React, {Component} from 'react';

import Container from 'react-bootstrap/Container';

//import Row from 'react-bootstrap/Row';

class Terms extends Component {
    render() {
        return (
            <div className="termsStyle">
                <Container>
                    <p>Effective as of April 1, 2019</p>

                    <p>
                        These Terms and Conditions (the “Terms”) set forth our mutual agreement as to your rights and
                        responsibilities when you use the Timeline Atlas website and services (the “Services").
                        By using any of the Services you are agreeing to these Terms. You are also agreeing to comply
                        with
                        our Timeline Atlas Community Rules.
                    </p>

                    <h4>Important Things for You to Understand When You Use Our Services</h4>
                    <p>You always maintain ownership of your data, but we need the ability to use your data for the
                        purposes set out in our Privacy Statement and these Terms.</p>
                    <p>Your data may be used to enhance our existing user experience or to develop new products and
                        services. Unless expressly stated otherwise, each new feature that we add to the Services will
                        also be subject to these Terms.</p>
                    <p>You may delete your data and account at any time.</p>

                    <h4>Contents</h4>
                    <ul>
                        <li>Eligibility to Use the Services</li>
                        <li>Your Use of the Services</li>
                        <li>Renewals and Cancellations for Timeline Atlas Services</li>
                        <li>Content Used in the Services</li>
                        <li>Termination or Suspension of your Account</li>
                        <li>Modifications to these Terms</li>
                        <li>Warranty</li>
                        <li>Limit of Liability</li>
                        <li>Your Indemnity</li>
                        <li>Services Offered by Other Companies</li>
                        <li>Controlling Law</li>
                        <li>Dispute Resolution</li>
                        <li>Miscellaneous</li>
                    </ul>


                    <ol>
                        <h5>
                            <li>Eligibility to Use the Services</li>
                        </h5>
                        <p>Users of the Services may include unregistered and registered guests. You may need to create
                            an account to use the Services. You must provide accurate, complete and current registration
                            information when you register for an account. The Services are intended for adults.</p>

                        <h5>
                            <li>Your Use of the Services</li>
                        </h5>
                        <p>Requirements for Using the Services: In exchange for your access to the Services, you
                            agree:</p>
                        <ul>
                            <li>To comply with the Timeline Atlas Community Rules</li>
                            <li>To comply with all applicable laws</li>
                            <li>Not to resell the Services or to resell, reproduce or publish any content or information
                                found on the Services, except as explicitly described in these Terms
                            </li>
                            <li>Not to circumvent, disable or otherwise interfere with security-related features of the
                                Services or features that prevent or restrict use or copying of any content or enforce
                                limitations on use of the Services or the content therein, including with bots,
                                crawlers, spiders, data miners, scraping or other automatic access tools
                            </li>
                            <li>You are responsible for all usage and activity on the Services made via your account
                            </li>
                            <li>You will contact us if you suspect your account has been breached or used without your
                                authorization
                            </li>
                        </ul>

                        <h5>
                            <li>Renewals and Cancellations for Timeline Atlas Services</li>
                        </h5>
                        <p>Your registration(s) to the Services and your purchase of products are subject to the Renewal
                            and Cancellation Terms, which are incorporated herein.</p>

                        <h5>
                            <li>Content Used in the Services</li>
                        </h5>
                        <p>Timeline Atlas Content: The Services contain records, indexes of content, and other content
                            that are owned by or are licensed to Timeline Atlas. We refer to this content as “Timeline
                            Atlas Content.” All Timeline Atlas Content is owned by or licensed to us and may be used
                            only in accordance with these Terms. You may use the Timeline Atlas Content only as
                            necessary for your personal use of the Services or your professional family history
                            research.</p>
                        <p>With respect to Timeline Atlas Content, you agree:</p>
                        <ul>
                            <li>To keep all copyright and other proprietary notices on any Timeline Atlas Content you
                                download or print; and
                            </li>
                            <li>Not to distribute, republish, or sell significant portions of any Timeline Atlas
                                Content.
                            </li>
                        </ul>
                        <p>Public Domain Content: Some Timeline Atlas Content may be in the public domain, and yet also
                            subject to restrictions on reuse. We refer to Timeline Atlas Content in the public domain as
                            “Public Domain Content.” You are free to use a small portion of Public Domain Content, but
                            you must obtain our written permission to use more than a small portion. If you have any
                            questions about your use of Public Domain Content, please contact us.</p>
                        <p>Personal Information and User Provided Content: When you use the Services, you provide us
                            with different types of information. For an explanation of Personal Information and a
                            description of the types of Personal Information you may provide to us, see our Privacy
                            Statement. You may ask us to delete your Personal Information as explained in our Privacy
                            Statement. In addition, you may provide us with information that is not considered Personal
                            Information. In these Terms, we refer to this type of information as “User Provided
                            Content.”</p>
                        <p>With respect to User Provided Content, you agree that:</p>
                        <ul>
                            <li>You are solely responsible for your User Provided Content;</li>
                            <li>You represent and warrant that you have all the necessary rights to upload or post your
                                User Provided Content and that your User Provided Content complies with the Timeline
                                Atlas Community Rules;
                            </li>
                            <li>You will provide Timeline Atlas, upon our request, with any documentation necessary to
                                evidence your compliance with these Terms; and
                            </li>
                            <li>Any User Provided Content that you have made public or shared (example: your timeline in
                                a Timeline Atlas visualization) may be used by other users as part of, or in conjunction
                                with, the Services. We will not be required to remove any information or User Provided
                                Content that you have made public.
                            </li>
                        </ul>

                        <p>Timeline Atlas has no responsibility or liability related to User Provided Content. While we
                            do not routinely monitor User Provided Content that is uploaded or posted to the Services,
                            we do reserve the right to do so and to use automated tools that monitor User Provided
                            Content for violations of these Terms, including, the Timeline Atlas Community Rules. We
                            reserve the right, but do not have the obligation, to remove or disable access to any User
                            Provided Content that we believe violates these Terms, including the Timeline Atlas
                            Community Rules.</p>

                        <p>If you have concerns that User Provided Content posted by other users may infringe your
                            rights, contain illegal material, or violate these Terms please contact us.</p>

                        <p>Additional User Information. You may voluntarily choose to provide additional information
                            about yourself or your family to Timeline Atlas. Additional User Information does not
                            include account, profile, payment, or usage details necessary to provide the Services, or
                            any User Provided Content. You may have the option to share your Additional User Information
                            with other users. Any sharing of Additional User Information with third parties is governed
                            by our Privacy Statement.</p>

                        <p>Ownership of Personal Information, Additional User Information and User Provided Content: You
                            own your Personal Information, Additional User Information, and User Provided Content, but
                            we need certain rights from you to use that information and content. By using the Services,
                            you grant us the right to collect, host, transfer, process, analyze, communicate and store
                            your Personal Information and Additional User Information in order to (a) provide the
                            Services to you and other users, (b) for the purposes described in these Terms and our
                            Privacy Statement, (c) to help our Users discover more about their timelines, and (d) for
                            any other purpose to which you expressly agree, such as sharing with others. Also, by
                            submitting User Provided Content through any of the Services, you grant Timeline Atlas a
                            sublicensable, worldwide, royalty-free license to host, store, copy, publish, distribute,
                            provide access to, create derivative works of, and otherwise use such User Provided Content
                            to the extent and in the form or context we deem appropriate on or through any media or
                            medium and with any technology or devices now known or hereafter developed or discovered.
                            This includes the right for Timeline Atlas to copy, display, and index your User Provided
                            Content. Timeline Atlas will own the indexes it creates. We will also have the right to
                            continue to use your User Provided Content, even if you stop using the Services, but only as
                            necessary for us to provide and improve the Services.</p>
                        <p><strong>Copyright and Trademark Notice:</strong> Each of the Services is protected by
                            copyright as a collective work or compilation, pursuant to U.S. copyright laws,
                            international conventions, and other copyright laws. The trademarks, service marks and logos
                            contained in the Services are owned by or licensed to us. We and our licensors retain title,
                            ownership and all other rights and interests in and to all Timeline Atlas Content in the
                            Services.</p>

                        <h5>
                            <li>Termination or Suspension of your Account</li>
                        </h5>
                        <p>We may limit, terminate, or suspend your access to the Services if you breach or act
                            inconsistently with the letter or spirit of these Terms or, the Timeline Atlas Community
                            Rules.</p>


                        <h5>
                            <li>Modifications to these Terms</li>
                        </h5>
                        <p>We have the right to modify these Terms or any additional terms that apply to a Service at
                            any time, including to reflect changes to the law or changes to our Services. We will notify
                            you of any material changes by posting information through the Services or via email. Such
                            material changes will not apply retroactively and will become effective thirty days after
                            they are posted, except that changes addressing new functions in the Services or changes
                            made for legal reasons will be effective immediately. Your continued use of the Services
                            after a change to the Terms will mean you accept the changes. If any changes are
                            unacceptable to you, you may stop using the Services and, if applicable, cancel your
                            registration as described here.</p>


                        <h5>
                            <li>Warranty</li>
                        </h5>
                        <p>Except as expressly set out in these Terms, we provide the Services and the Timeline Atlas
                            Content to you on an “AS-IS” basis. To the maximum extent permitted by law, we disclaim all
                            warranties express or implied, including the implied warranties of non-infringement,
                            merchantability, and fitness for a particular purpose. We do not make any promises (a) about
                            the Timeline Atlas Content, (b) about the specific functionality of the Services, (c) about
                            the quality, accuracy, reliability, or availability of the Timeline Atlas Content or
                            Services, or (d) that the Timeline Atlas Content or Services will be free from viruses or
                            other harmful components.</p>


                        <h5>
                            <li>Limit of Liability</li>
                        </h5>
                        <p>We limit our liability to the fullest extent allowed by law. We will not be liable for any
                            unintentional damage, any actual, incidental, or consequential damage, or for any loss or
                            claim of any kind. Some jurisdictions do not allow us to have a broad limit on our
                            liability. If you live in one of those jurisdictions, some of these limitations may not
                            apply to you. If you are dissatisfied with any portion of the Services or with any statement
                            in these Terms, your sole remedy is to stop using the Services and cancel your registration
                            as described here. <strong>Our total liability in any matter related to the Services or
                                these Terms is limited to the aggregate amount you paid us during the 12-month period
                                preceding the event giving rise to the liability.</strong></p>

                        <h5>
                            <li>Your Indemnity</li>
                        </h5>
                        <p>You agree that you will indemnify and hold Timeline Atlas, our employees, and our agents
                            harmless from any claims, damages, or other expenses (including attorney's fees) that result
                            from your use of the Services and (a) your violation of these Terms or other documents
                            incorporated herein by reference; (b) your violation of another person's rights; or (c) any
                            claim related to your User Provided Content, including a claim that your User Provided
                            Content caused damage to another person. This indemnification obligation will continue after
                            you stop using the Services. In addition, you release Timeline Atlas from all claims,
                            demands, actions, or suits in connection with your User Provided Content, including any
                            liability related to our use or non-use of your User Provided Content, claims for
                            defamation, invasion of privacy, right of publicity, emotional distress or economic
                            loss.</p>


                        <h5>
                            <li>Services Offered by Other Companies</li>
                        </h5>
                        <p>We may offer you the opportunity to purchase services from companies other than Timeline
                            Atlas. Use of those services will be subject to the terms and conditions of the companies
                            offering the services. Please read those terms carefully. We have no responsibility related
                            to any such third-party services.</p>

                        <h5>
                            <li>Controlling Law</li>
                        </h5>
                        <p>If you access the Services on our websites in the United States, the laws of Illinois and
                            those of the United States of America, govern these Terms and the use of the Services. All
                            claims brought in the United States will be subject to the jurisdiction of the courts of the
                            State of Illinois.</p>

                        <h5>
                            <li>Dispute Resolution</li>
                        </h5>
                        <p>If a dispute arises between you and Timeline Atlas, our goal is to provide a cost-effective
                            means of quickly resolving the dispute. If you have any concern or dispute about the
                            Services, you agree to first try to resolve the dispute informally by contacting us.</p>
                        <p>For U.S. Customers:</p>
                        <p>If your dispute is not resolved within 30 days after contacting us, then you and Timeline
                            Atlas agree that we will resolve it through final and binding arbitration, with the
                            following three exceptions:</p>
                        <ol>
                            <li>You may assert your dispute, if it qualifies, in small claims court.</li>
                            <li>Both you and Timeline Atlas may bring a suit in court in the state of Illinois only for
                                a claim of infringement or other misuse of intellectual property rights. In this case,
                                we both waive any right to a jury trial.
                            </li>
                            <li>If it qualifies, you may bring a claim to the attention of a relevant federal, state, or
                                local agency that may seek relief against us on your behalf.
                            </li>
                        </ol>

                        <p>Arbitration: Timeline Atlas may use arbitration to settle disputes.</p>
                        <p>No Class Actions: You may only resolve disputes with us on an individual basis, and may not
                            bring a claim as a plaintiff or a class member in a class, consolidated, or representative
                            action.</p>
                        <p>Injunctive Relief: If you use the Services in violation of these Terms, you agree that we are
                            entitled to any injunctive remedy or an equivalent type of urgent legal relief in the
                            appropriate jurisdiction.</p>
                        <p>This dispute resolution process will continue after you have stopped using the Services.</p>
                        <p>Nothing in this Agreement affects your rights as a consumer to rely on such mandatory
                            provisions of local law. Nothing contained in this clause shall limit Timeline Atlas's
                            rights to bring enforcement proceedings in another jurisdiction or to seek interim,
                            protective or provisional relief in the courts of another jurisdiction.</p>


                        <h5>
                            <li>Miscellaneous</li>
                        </h5>
                        <p>Entire Agreement: These Terms, including all rules, guidelines, and other documents
                            incorporated herein by reference, state the entire agreement between you and Timeline Atlas
                            regarding your use of the Services and supersede any prior agreements we may have relating
                            to the Services.</p>
                        <p>Notification of Changes to the Services: Timeline Atlas may contact you within the Services,
                            via email to inform you of changes to the Services or these Terms. You agree that contact in
                            any of these ways will satisfy any legal communication requirements, including that
                            communication be in writing.</p>
                        <p>Feedback: If you submit feedback or suggestions about Timeline Atlas or our Services, we may
                            use your feedback or suggestions for any purpose without any obligation to you.</p>
                        <p>Assignment: We reserve the right to assign or transfer our rights and obligations under this
                            Agreement. These terms are personal to you and, as a result, you may not, without the
                            written consent of Timeline Atlas, assign or transfer any of your rights and obligations
                            under this Agreement. There shall be no third-party beneficiaries to this Agreement.</p>
                        <p>If Timeline Atlas is Acquired: If Timeline Atlas or its businesses are acquired or
                            transferred to another entity (in whole or part and including in connection with bankruptcy
                            or similar proceedings), Timeline Atlas has the right to share your Personal Information,
                            User Provided Content and Additional User Information with that entity. These Terms will
                            continue to apply to the Services until you receive notification of changes to the Terms or
                            Services.</p>
                        <p>Severability: The un-enforceability of any particular section or clause in these Terms will
                            not affect the enforceability of the remaining Terms. We may replace any unenforceable
                            section or clause with a similar one that is enforceable.</p>
                    </ol>

                    <p>No Waiver: Our failure to enforce any provision of these Terms is not a waiver of our rights
                        under that provision.</p>

                </Container>
            </div>
        );
    }
}

export default Terms;