import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import Carousel from "react-bootstrap/Carousel";

import gallery1 from '../assets/gallery/g1.jpg';
import gallery2 from '../assets/gallery/g2.jpg';
import gallery3 from '../assets/gallery/g3.jpg';
import gallery4 from '../assets/gallery/g4.jpg';
import gallery5 from '../assets/gallery/g5.jpg';
import gallery6 from '../assets/gallery/g6.jpg';
import gallery7 from '../assets/gallery/g7.jpg';



class Gallery extends Component {
    render() {
        return (
            <Container>
                <p>Buy etched crystal maps of your timeline at <a href="https://www.stephencartwright.com/timeline-atlas-store" rel="noopener noreferrer" target="_blank">https://www.stephencartwright.com/timeline-atlas-store </a>
               </p>
                <Carousel className="galleryCarousel">
                    <Carousel.Item>
                        <img className="galleryImage" src={gallery1} alt="Timeline Atlas Crystal Map (Small Rectangular Tower)"/>
                        <Carousel.Caption><p className="galleryCaption">Timeline Atlas Crystal Map (Small Rectangular Tower)</p></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="galleryImage" src={gallery2} alt="Timeline Atlas Crystal Map (Large Rectangle Vertical)"/>
                        <Carousel.Caption><p className="galleryCaption">Timeline Atlas Crystal Map (Large Rectangle Vertical)</p></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="galleryImage" src={gallery3} alt="Timeline Atlas Crystal Map (Large Rectangle Horizontal)"/>
                        <Carousel.Caption><p className="galleryCaption">Timeline Atlas Crystal Map (Large Rectangle Horizontal)</p></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="galleryImage" src={gallery4} alt="Timeline Atlas Crystal Map (Large Globe Cube)"/>
                        <Carousel.Caption><p className="galleryCaption">Timeline Atlas Crystal Map (Large Globe Cube)</p></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="galleryImage" src={gallery5} alt="Timeline Atlas Crystal Map (Small Globe Cube)"/>
                        <Carousel.Caption><p className="galleryCaption">Timeline Atlas Crystal Map (Small Globe Cube)</p></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="galleryImage" src={gallery6} alt="Timeline Atlas Printed Map (Perspective View)"/>
                        <Carousel.Caption><p className="galleryCaption">Timeline Atlas Printed Map (Perspective View)</p></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="galleryImage" src={gallery7} alt="Timeline Atlas Printed Map (Time View)"/>
                        <Carousel.Caption><p className="galleryCaption">Timeline Atlas Printed Map (Time View)</p></Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </Container>
        );
    }
}

export default Gallery;