import {LINE_COLORS} from "../constants";
import {isValidPoint} from "./graphService";
import database from "../persistence/database";
import store from "../state/store";
import * as actions from "../actions/actionList";
import {makeBlankPerson} from "../state/testdata";

class Utils {
    sortValidLocationsByDate(locations) {
        return [...locations].sort(this.compareTwoLocations).filter(location => isValidPoint(location));
    }

    getDateWithoutTimezone(dateWithTimezone) {
        return (new Date(dateWithTimezone.getUTCFullYear(), dateWithTimezone.getUTCMonth(), dateWithTimezone.getUTCDate(), 12));
    }

    formatDateToString(date) {
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();

        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }

        return year + '-' + month + '-' + day;
    }

    compareTwoLocations(locationA, locationB) {
        if (!locationA.start || !locationB.start)
            return 0;
        const dateA = new Date(locationA.start);
        const dateB = new Date(locationB.start);
        return dateA.getTime() - dateB.getTime();
    }

    getLineColor(index) {
        return LINE_COLORS[index % LINE_COLORS.length];
    }

    isBlank(item) {
        return item === undefined || item === null
    }

    updateUserData() {
        const admins = database.ref().child('admins');

        admins.on('value', snap => {
            const state = store.getState();
            if (snap.val().includes(state.currentUser) && !state.isAnonymous) {
                this.setIsAdmin(true);
            } else {
                this.setIsAdmin(false);
            }
        });

        const data = database.ref().child('users');
        data.on('value', snap => {
            const state = store.getState();
            if (!state.isAnonymous) {
                const singleUserData = snap.val()[state.currentUser];
                if (singleUserData && singleUserData.people) {
                    const people = singleUserData.people;
                    this.loadPeople(people);

                    const optedIn = singleUserData.isOptedIn;
                    this.setIsOptedIn(optedIn || false);

                }
            } else {
                const anonymousPeople = window.localStorage.getItem('people');

                if (anonymousPeople)
                    this.loadPeople(JSON.parse(anonymousPeople));
                else
                    this.loadPeople([makeBlankPerson()]);
            }
        });
    }

    stateHasValidPeople(state) {
        if (state.people[0] != null) {
            if (state.people[0].locations != null) {
                if (state.people[0].locations[0] != null) {
                    return true;
                }
            }
        } else return false;
    }

    storeAnonymousPeople(people) {
        window.localStorage.setItem('people', JSON.stringify(people));
    }

    clearAnonymousPeople() {
        window.localStorage.removeItem('people');
    }

    pushUserData() {
        const state = store.getState();

        database.ref('users/' + state.currentUser).update({
            isOptedIn: state.isOptedIn || false,
            people: state.people,
            email: state.currentUserEmail
        })
            .then(() => {
                store.dispatch({
                    type: actions.updateSavedMessage,
                    visible: true
                });

                setTimeout(() => {
                    store.dispatch({
                        type: actions.updateSavedMessage,
                        visible: false
                    })
                }, 3000)
            });
    }

    setIsAdmin(event) {
        store.dispatch({
            type: actions.setIsAdmin,
            isAdmin: event
        });
    }

    setCurrentUser(event) {
        store.dispatch({
            type: actions.setCurrentUser,
            email: event.email,
            uuid: event.uid
        });
    }

    setIsAnonymous(event) {
        store.dispatch({
            type: actions.setIsAnonymous,
            isAnonymous: event
        });
    }

    loadPeople(event) {
        store.dispatch({
            type: actions.loadPeopleFromDatabase,
            people: event
        });
    }

    setIsOptedIn(event) {
        store.dispatch({
            type: actions.setIsOptedIn,
            isOptedIn: event
        });
    }

    getAggregateDataFromDatabase() {
        const data = database.ref('users').orderByChild('isOptedIn').startAt(true).endAt(true);
        data.once('value', snap => {
            const optedInUsers = snap.val();
            const people = [];
            const keywordsCount = {};
            const locationsCount = {};
            Object.keys(optedInUsers).forEach(key => {
                const user = optedInUsers[key];
                if (user.people) {
                    user.people.forEach(person => {


                        if (person.keywords) {
                            const uniqueKeywords = this.getDistinctValuesFromArray(person.keywords);
                            uniqueKeywords.forEach(keyword => {
                                if (keyword !== '') {
                                    if (keywordsCount[keyword])
                                        keywordsCount[keyword] += 1;
                                    else
                                        keywordsCount[keyword] = 1;
                                }
                            });
                        }

                        const locations = [];
                        if (person.locations) {
                            const cities = [];
                            person.locations.forEach(location => {
                                if (location.city && !cities.includes(location.city)) {
                                    cities.push(location.city);
                                }
                                locations.push({
                                    start: location.start,
                                    longitude: location.longitude,
                                    latitude: location.latitude,
                                    city: location.city,
                                });
                            });
                            cities.forEach(city => {
                                if (locationsCount[city])
                                    locationsCount[city] += 1;
                                else
                                    locationsCount[city] = 1;
                            })
                        }

                        people.push({
                            keywords: person.keywords || [],
                            locations: locations,
                            visible: true,
                            deceased: person.deceased,
                            deathDate: person.deathDate
                        });
                    });
                }
            });

            const aggregateData = {
                people,
                keywordsCount,
                locationsCount
            };

            store.dispatch({
                type: actions.setAggregateData,
                aggregateData
            })
        });
    }

    personHasKeyword(person, keyword) {
        return person.keywords.includes(keyword);
    }

    personHasCity(person, city) {
        return person.locations.reduce((hasCityAlready, location) => {
            if (hasCityAlready)
                return hasCityAlready;
            else
                return location.city === city;
        }, false);
    }

    getDistinctValuesFromArray(array) {
        return array.filter((item, index, self) => {
            return self.indexOf(item) === index;
        })
    }

    checkForUniqueLatLngs(latLngs) {
        for (let i = 0; i < latLngs.length; i++) {
            for (let j = 0; j < latLngs.length; j++) {
                if (latLngs[i][0] !== latLngs[j][0] || latLngs[i][1] !== latLngs[j][1])
                    return true;
            }
        }

        return false;
    }

    loadAllUsersForLookup() {
        const data = database.ref('users');
        data.once('value', (snap) => {
            const users = snap.val();

            store.dispatch({
                type: actions.setUsersForLookup,
                users: users
            })
        })
    }
}

const utils = new Utils();

export default utils;