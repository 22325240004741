import utils from "./utils";
import {isValidPoint} from "./graphService";
import {DEFAULT_BOUNDS} from "../components/map";

export function get2DLinesWithColor(people){
    const lines = [];
    people.forEach(function(person, person_index){
        let line = get2DPointsFromPerson(person);
        if(line && person.visible){
            lines.push({
                positions: line,
                color: utils.getLineColor(person_index),
            });
        }
    });
    return lines;
}

function get2DPointsFromPerson(person) {
    const points = utils.sortValidLocationsByDate(person.locations)
        .map(make2DPointFromLocation)
        .filter(point => {
            return point !== null;
        });
    return points.length > 0 ? points : null;
}

function make2DPointFromLocation(location) {
    if (!isValidPoint(location))
        return null;
    else
        return [location.latitude, location.longitude];
}


export function getBounds(polylines) {
    const latLngs = getAllLatLongs(polylines);
    const hasUniqueLatLngs = utils.checkForUniqueLatLngs(latLngs);
    if (latLngs.length > 1 && hasUniqueLatLngs) {
        return latLngs;
    } else if (latLngs.length >= 1) {
        return [
            [latLngs[0][0] + 3, latLngs[0][1] + 3],
            [latLngs[0][0] - 3, latLngs[0][1] - 3]
        ];
    }
    return DEFAULT_BOUNDS;
}

export function getAllLatLongs(polylines) {
    if(polylines.length > 0){
        let positions = [];
        polylines.forEach(function(p){
            const polylinePositions = p.positions || [];
            polylinePositions.forEach(function(position) {
                if(!isNaN(position[0] && !isNaN(position[1])))
                    positions.push(position);
            });
        });
        return positions;

    }
    else return [];
}