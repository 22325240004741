import React, {Component} from 'react';

class Chevron extends Component {

    render(){
        if(this.props.direction){
            return(
                <i className="fas fa-chevron-right collapseChevron" ></i>
            );
        }
        else {
            return (
                <i className="fas fa-chevron-down collapseChevron"></i>
            );
        }
    }
}

export default Chevron;