import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../actions/actionList';
import geocoding from '../services/geocoding';
import utils from '../services/utils';
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";

function mapStateToProps(state) {
    return {};
}

class Location extends Component {
    constructor(props) {
        super(props);

        this.handleStartChange = this.handleStartChange.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.remove = this.remove.bind(this);
        this.getGeocodingData = this.getGeocodingData.bind(this);
    }

    render() {
        return (
            <div className="form-group border border-dark rounded p-2">
                { this.renderStart() }
                { this.renderLocation() }
                <Button
                    className="mr-2"
                    variant="outline-dark"
                    size="sm">
                    Update
                </Button>
                <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={this.remove}>
                    Delete
                </Button>
            </div>
        );
    }

    renderStart() {
        const date = this.props.location.start ? new Date(this.props.location.start) : '';

        return (
            <div className="form-group">
                <label>Date:</label>
                <div>
                    <DatePicker
                        selected={date}
                        onChange={this.handleStartChange}
                        className="form-control"/>
                </div>
            </div>
        );
    }

    renderLocation() {
        return (
            <div className="form-group">
                <label>Location:</label>
                <input className="form-control" type="text" value={this.props.location.stringLocation}
                       onChange={this.handleLocationChange}
                       onBlur={this.getGeocodingData}/>
            </div>
        );
    }

    handleStartChange(date) {
        if(date) {
            let dateWithoutTimezone = utils.getDateWithoutTimezone(date);
            this.props.dispatch({
                type: actions.updateStart,
                start: dateWithoutTimezone.toString(),
                person_index: this.props.personIndex,
                location_index: this.props.index
            });
        }
    }

    handleLocationChange(event) {
        this.props.dispatch({
            type: actions.updateStringLocation,
            stringLocation: event.target.value,
            person_index: this.props.personIndex,
            location_index: this.props.index
        });
    }

    getGeocodingData() {
        geocoding.geocodeAddress(this.props.location.stringLocation)
            .then(geocodedData => {
                this.props.dispatch({
                    type: actions.updateGeocode,
                    person_index: this.props.personIndex,
                    location_index: this.props.index,
                    ...geocodedData
                });
            })
            .catch(status => {
                if (status === 'ZERO_RESULTS')
                    console.error(`The location ${this.props.location.stringLocation} didn't match anything!`);
            });
    }

    remove() {
        this.props.dispatch({
            type: actions.removeLocation,
            person_index: this.props.personIndex,
            location_index: this.props.index
        });
    }

}

export default connect(
    mapStateToProps,
)(Location);