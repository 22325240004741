import React, {Component} from 'react';
import {connect} from 'react-redux';
import store from '../state/store';
import auth from '../authentication/auth';
import utils from '../services/utils';
import * as actions from "../actions/actionList";
import Dropdown from "react-bootstrap/Dropdown";

function mapStateToProps(state) {
    return {
        isAnonymous: state.isAnonymous,
        email: state.currentUserEmail,
        accountId: state.currentUser,
    };
}

class LogInOutButton extends Component {
    render() {
        const state = store.getState();
        if (state.isAnonymous === true || state.isAnonymous === null) {
            return (
                <button
                    className="btn btn-sm btn-light"
                    type="button"
                    data-toggle="modal"
                    data-target="#authModal">
                    Log in
                </button>
            );
        } else {
            return (
                <div>
                    <Dropdown>
                        <Dropdown.Toggle variant="light" >
                            <i className="fas fa-user"></i>  {this.props.email}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="#" onClick={evt => this.signOut(evt)}>Log out</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            );
        }
    }

    signOut(evt) {
        auth.signOut()
            .then(() => {
                utils.clearAnonymousPeople();
                store.dispatch({
                    type: actions.resetToDefault,
                    hasEmailBeenSent: false
                });
            });
    }

}

export default connect(
    mapStateToProps,
)(LogInOutButton);