import React, {Component} from 'react';
import {connect} from 'react-redux';
import emailLinkAuthentication from "../authentication/EmailLinkAuthentication";
import store from "../state/store";
import * as actions from "../actions/actionList";

function mapStateToProps(state) {
    return {
        isAnonymous: state.isAnonymous,
        hasEmailBeenSent: state.hasEmailBeenSent || false,
        hasInvalidEmailBeenSent: state.hasInvalidEmailBeenSent || false
    };
}

class AuthModal extends Component {
    constructor(props) {
        super(props);
        this.emailInput = React.createRef();
    }

    componentDidMount() {
        const state = store.getState();
        mapStateToProps(state);
    }

    render() {
        return (
            <div className="modal fade" id="authModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <p className="modal__message">No need to remember a password for this site! Timeline Atlas uses a secure, password-less login system. Every time you want to log in, you will enter your email into this login form and receive an email with a link you will click. You will not have a password for Timeline Atlas; your access to this email account serves as your password.</p>
                        <p className="modal__help-text">Enter your email to log in or to log in for the first time</p>
                        <form className="modal-body" onSubmit={(event) => this.logInWithEmail(event)}>
                            <input type="email" ref={this.emailInput}/>
                            <button type="submit">
                                Go
                            </button>
                        </form>
                        { this.renderMessage() }
                    </div>
                </div>
            </div>
        );
    }

    renderMessage() {
        if(this.props.hasEmailBeenSent)
            return (
                <p className="modal__action-complete-message">
                    Your login link has been sent to your email address
                </p>
            );
        else if(this.props.hasInvalidEmailBeenSent)
            return (
                <p className="modal__action-failed-message">
                    Please enter a valid email address.
                </p>
            )
    }

    logInWithEmail(event) {
        event.preventDefault();
        const email = this.emailInput.current.value;
        if(this.isValidEmail(email)) {
            emailLinkAuthentication.startLogIn(email);
            this.validEmailSubmitted();
        } else {
            this.invalidEmailSubmitted()
        }
    }

    isValidEmail(email)
    {
        var emailRegEx = /\S+@\S+\.\S+/;
        return emailRegEx.test(email);
    }

    validEmailSubmitted(){
        store.dispatch({
            type: actions.setHasEmailBeenSent,
            hasEmailBeenSent: true
        });
    }

    invalidEmailSubmitted(){
        store.dispatch({
            type: actions.setHasInvalidEmailBeenSent,
            hasInvalidEmailBeenSent: true
        })
    }
}

export default connect(
    mapStateToProps,
)(AuthModal);