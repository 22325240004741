import React, {Component} from 'react';
import utils from '../services/utils';
class colorBorder extends Component {
    render(){
        const borderStyle = {
            'backgroundColor': utils.getLineColor(this.props.index),
            'height': '8px',
            'border': '1px solid gray',
        };
        return(
            <div style={borderStyle}>
            </div>
        )
    }
}

export default colorBorder;