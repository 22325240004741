import React, {Component} from 'react';
import {connect} from 'react-redux';
import Location from "./location";
import * as actions from "../actions/actionList";
import ColorBorder from './ColorBorder';
import Chevron from './Chevron';
import Eye from './Eye';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import HelpTextIcon from './HelpTextIcon';
import utils from '../services/utils';
import {keywordHelpText, locationHelpText} from "../constants";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";


function mapStateToProps(state) {
    return {};
}

class Person extends Component {

    constructor(props) {
        super(props);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.updateVitalStatus = this.updateVitalStatus.bind(this);
        this.updateDeathDate = this.updateDeathDate.bind(this);
        this.addKeyword = this.addKeyword.bind(this);
        this.removeKeyword = this.removeKeyword.bind(this);
        this.addLocation = this.addLocation.bind(this);
        this.remove = this.remove.bind(this);
        this.togglePersonCollapsed = this.togglePersonCollapsed.bind(this);
        this.togglePersonVisible = this.togglePersonVisible.bind(this);
        this.modalClose = this.modalClose.bind(this);

        this.state = {
            modalShow: false
        };
    }

    render() {
        return (
            <div>
                <ColorBorder index={this.props.index}/>
                <div className="form-group personBorder">
                    <div className="collapseHeader" onClick={this.togglePersonCollapsed}
                         aria-expanded="true" aria-controls={'person-' + this.props.index}>
                        <span className="personName">{this.props.person.name.first || 'Name'}</span>
                        <Chevron direction={this.props.person.collapsed}/> <Eye visible={this.props.person.visible}
                                                                                clickHandler={this.togglePersonVisible}/>
                    </div>
                    <Collapse in={!this.props.person.collapsed}>
                        <div className="mt-2" id={'person-' + this.props.index}>
                            <div className="nameForm">
                                <span>
                                <input className="form-control mb-1" type="text" placeholder={"First name"}
                                       value={this.props.person.name.first} onChange={this.handleFirstNameChange}/>
                                <input className="form-control" type="text" placeholder={"Last name"}
                                       value={this.props.person.name.last} onChange={this.handleLastNameChange}/>
                                </span>
                            </div>
                            <span>
                                <ButtonToolbar className="mt-3 mb-3">
                                    <ToggleButtonGroup type="radio" name="options" value={this.props.person.deceased} onChange={this.updateVitalStatus}>
                                        <ToggleButton className="btn-light btn-sm" value={false}>Living</ToggleButton>
                                        <ToggleButton className="btn-light btn-sm" value={true}>Deceased</ToggleButton>
                                    </ToggleButtonGroup>
                                </ButtonToolbar>
                            </span>
                            <Collapse in={this.props.person.deceased} className="mb-3">
                                {this.renderDateOfDeath()}
                            </Collapse>
                            {this.renderKeywords()}
                            {this.renderLocations()}
                            <Button variant="danger" size="sm" onClick={() => this.setState({modalShow: true})}>
                                Remove Person
                            </Button>

                            <Modal show={this.state.modalShow} onHide={this.modalClose} size="md"
                                   aria-labelledby="contained-modal-title-vcenter" centered>
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        Remove Person
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Are you sure you want to delete this person?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="danger" size="sm" onClick={this.remove}>Remove Person</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </Collapse>
                </div>
            </div>
        );
    }

    renderDateOfDeath() {
        const date = this.props.person.deathDate ? new Date(this.props.person.deathDate) : '';

        return (
            <div>
                <label>Date of death:</label>
                <div>
                    <DatePicker
                        selected={date}
                        onChange={this.updateDeathDate}
                        className="form-control"/>
                </div>
            </div>
        );
    }

    renderKeywords() {
        const keywords = this.props.person.keywords || [];
        return (
            <div className="form-group">
                <h6>
                    Keywords <HelpTextIcon key={"keyword-tooltip-" + this.props.index}
                                           index={this.props.index}
                                           message={keywordHelpText}/>
                </h6>
                {keywords.map((keyword, index) => {
                    return (
                        <div className="input-group mb-1" key={'keyword-' + index}>
                            <input
                                className="form-control"
                                type="text" value={keyword}
                                data-keyword-index={index}
                                onChange={this.handleKeywordChange}
                                placeholder="Keyword"
                                aria-label="Add a keyword"/>
                            <div className="input-group-append">
                                <Button
                                    variant="outline-danger" size="sm"
                                    data-keyword-index={index}
                                    onClick={this.removeKeyword}>
                                    Remove
                                </Button>
                            </div>
                        </div>
                    )
                })}
                <Button variant="outline-dark" size="sm" onClick={this.addKeyword}>Add Keyword</Button>
            </div>
        );
    }

    renderLocations() {
        const locations = this.props.person.locations || [];
        return (
            <div className='form-group'>
                <h6>
                    Locations <HelpTextIcon key={"locations-tooltip-" + this.props.index}
                                            index={this.props.index} message={locationHelpText}/>
                </h6>
                {locations.map((location, index) => {
                    return (<Location key={'location-' + index} location={location} index={index}
                                      personIndex={this.props.index}/>)
                })}
                <Button variant="outline-dark" size="sm" onClick={this.addLocation}>New
                    Location</Button>
            </div>
        )
    }

    handleFirstNameChange(event) {
        this.props.dispatch({
            type: actions.updateFirstName,
            first: event.target.value,
            person_index: this.props.index
        });
    }

    handleLastNameChange(event) {
        this.props.dispatch({
            type: actions.updateLastName,
            last: event.target.value,
            person_index: this.props.index
        });
    }

    updateVitalStatus(isDeceased) {
        this.props.dispatch({
            type: actions.updateVitalStatus,
            status: isDeceased,
            person_index: this.props.index
        });
    }

    updateDeathDate(date) {
        if (date) {
            let dateWithoutTimezone = utils.getDateWithoutTimezone(date);
            this.props.dispatch({
                type: actions.updateDeathDate,
                deathDate: dateWithoutTimezone.toString(),
                person_index: this.props.index
            });
        }
    }

    handleKeywordChange(event) {
        this.props.dispatch({
            type: actions.updateKeyword,
            person_index: this.props.index,
            keyword: event.target.value,
            keyword_index: event.target.getAttribute('data-keyword-index')
        });
    }

    addKeyword() {
        this.props.dispatch({
            type: actions.addKeyword,
            person_index: this.props.index,
            keyword: ''
        });
    }

    removeKeyword(event) {
        this.props.dispatch({
            type: actions.removeKeyword,
            person_index: this.props.index,
            keyword_index: event.target.getAttribute('data-keyword-index')
        });
    }

    addLocation() {
        this.props.dispatch({
            type: actions.addLocation,
            person_index: this.props.index,
        });
    }

    remove() {
        this.props.dispatch({
            type: actions.removePerson,
            person_index: this.props.index
        });
        this.setState({modalShow: false});
    }

    togglePersonCollapsed() {
        this.props.dispatch({
            type: actions.togglePersonCollapsed,
            person_index: this.props.index
        });
    }

    togglePersonVisible(event) {
        event.cancelBubble = true;
        event.stopPropagation();
        this.props.dispatch({
            type: actions.togglePersonVisible,
            person_index: this.props.index
        });
    }

    modalClose() {
        this.setState({modalShow: false});
    }
}

export default connect(
    mapStateToProps,
)(Person);