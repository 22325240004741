import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';

class Community extends Component {
    render() {
        return (
            <div>
                <Container>
                    <h4>Community Guidelines</h4>

                    <p>For the safety and enjoyment of the Timeline Atlas community, the following rules clarify what is
                        allowed and prohibited on Timeline Atlas. If you think something violates these rules, please
                        let us know and we will do our best to address the situation. Your help is appreciated making
                        Timeline Atlas a safe and comfortable environment for everyone.</p>

                    <h6>Site Access and Security</h6>

                    <p>While using your Timeline Atlas account or any Timeline Atlas Services, you:</p>
                    <ul>
                        <li>Must comply with all applicable laws.</li>
                        <li>Must have your own Timeline Atlas account, and select a unique username and password.</li>
                        <li>May not permit a child under the age of 13 to publish information or post content through
                            your Timeline Atlas account.
                        </li>
                        <li>Have permission to enter other people’s information in your account.</li>
                        <li>Must provide valid and complete contact information, including a valid email address.</li>
                        <li>May not select a username that is hateful, obscene, or otherwise inappropriate, as
                            determined by Timeline Atlas, in its sole discretion.
                        </li>
                        <li>Must never misrepresent your identity, either to Timeline Atlas or to other users.</li>
                        <li>Must not interfere or attempt to interfere with Timeline Atlas services, including using any
                            software program, virus or routine to block, obscure, modify any Timeline Atlas Content or
                            web pages, or to destroy another person’s software, hardware, or telecommunications
                            equipment.
                        </li>
                    </ul>

                    <h6>Community Safety</h6>

                    <p>While using your Timeline Atlas account or any Timeline Atlas Services, you may not:</p>
                    <ul>
                        <li>Post or share any content that is illegal, threatening, harmful, abusive or otherwise
                            objectionable, including content or communications that are degrading, defamatory, vulgar,
                            hateful, libelous, fraudulent, obscene, pornographic, sexually explicit, sexist, racist, or
                            invasive of another's privacy.
                        </li>
                        <li>Stalk, harass, threaten or verbally attack any user or Timeline Atlas employee, or abuse
                            Timeline Atlas resources, such as misusing a Timeline Atlas employee’s time.
                        </li>
                        <li>Post or share content containing any person’s personal, proprietary, or confidential
                            information such as a person’s address without their permission. In the case of minors,
                            you’ll need the consent of their parent or guardian.
                        </li>
                    </ul>
                    <h6>Respect for Intellectual Property</h6>

                    <p>While using your Timeline Atlas account and the Timeline Atlas Services, you may not:</p>
                    <ul>
                        <li>Post or share content that is protected by copyright or trademark, or that does not belong
                            to you, including pictures and historical documents posted by other users, unless you have
                            express permission from the owner of the copyright or trademark for such content.
                        </li>
                        <li>Reproduce, reverse-engineer, or sell any portion of Timeline Atlas’s Services or Timeline
                            Atlas Content, or systematically download data from Timeline Atlas databases to make or
                            populate another database for any other purpose.
                        </li>
                        <li>“Deep link” or frame, inline link, or mirror any part of any Timeline Atlas Services without
                            Timeline Atlas’s written permission.
                        </li>
                    </ul>
                    <h6>Spam is Prohibited</h6>

                    <p>You may not use your Timeline Atlas account or the Timeline Atlas Services (or any information
                        obtained through the Services) to post or make available any advertising, promotional materials,
                        junk mail, spam, chain letters, or any other form of solicitation.</p>

                    <h6>Legal Details</h6>

                    <p>The Timeline Atlas Community Rules apply to all users of Timeline Atlas’s Services and are
                        intended to protect you and the entire Timeline Atlas community. Any capitalized terms used in
                        these Rules shall have the same meaning as set out in the Timeline Atlas Terms and Conditions
                        and Privacy Statement.</p>

                    <p>This list of prohibited conduct is not exhaustive. Timeline Atlas prohibits users of the Services
                        from engaging in any activity that Timeline Atlas, in its sole discretion, determines to be
                        offensive, interferes with the rights of others, or causes harm to any person or entity.
                        Timeline Atlas can’t be responsible for the content our users create and share in the community,
                        but we reserve the right to remove any posts or comments that violate these rules or our Terms
                        and Conditions.</p>

                    <p>Serious or repeat violations or offenses will subject the responsible user to account
                        termination, at Timeline Atlas’s sole discretion.</p>

                </Container>
            </div>
        );
    }
}

export default Community;