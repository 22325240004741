import React, {Component} from 'react';
import {connect} from 'react-redux';
import Plot from 'react-plotly.js';
import * as graphService from '../services/graphService';
import utils from "../services/utils";


function mapStateToProps(state) {
    if(!state.aggregateData) {
        utils.getAggregateDataFromDatabase();
        return { peopleTrace: null }
    } else {
        const keywordFilter = state.keywordFilter || null;
        const locationFilter = state.locationFilter || null;
        const peopleTrace = [];
        state.aggregateData.people
            .forEach((person, index) => {
                if(keywordFilter && !utils.personHasKeyword(person, keywordFilter))
                    return;
                if(locationFilter && !utils.personHasCity(person, locationFilter))
                    return;

                peopleTrace.push(graphService.get3DLineForPerson(person, index));
            });

        return { peopleTrace };
    }
}

class AggregateGraph extends Component {
    render() {
        let layoutConfig = graphService.getLayoutConfig();
        layoutConfig["showlegend"] = false;
        layoutConfig["width"] = 500;
        let configConfig = graphService.getPlotlyConfig();
        if(this.props.peopleTrace)
            return (
                <div>
                    <Plot
                        data={this.props.peopleTrace}
                        layout={layoutConfig}
                        config={configConfig}
                    />
                </div>
            );
        else
            return (
                <div></div>
            )
    }
}

export default connect(
    mapStateToProps,
)(AggregateGraph);