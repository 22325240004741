import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Route,
    NavLink
} from 'react-router-dom';
import './App.css';
import 'react-datepicker/dist/react-datepicker.css';
import Home from './routes/home';
import Timeline from './routes/timeline';
import Gallery from "./routes/gallery";
import About from "./routes/about";
import Privacy from "./routes/privacy";
import UserLookup from "./routes/userLookup";
import {Provider} from 'react-redux'
import store from './state/store';
import LogInOutButton from "./components/LogInOutButton";
import AuthModal from "./components/AuthModal";
import logo from './assets/logo.png'
import Terms from "./routes/terms";
import Community from "./routes/community";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Router>
                    <div>
                        <Navbar expand="lg">
                            <Navbar.Brand href="/">
                                <img src={logo} alt="Timeline Atlas Logo" height="100"/>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto">
                                    <li className="nav-item"><NavLink className="nav-link" to="/timeline" activeClassName="active">Timeline</NavLink></li>
                                    <li className="nav-item"><NavLink className="nav-link" to="/gallery" activeClassName="active">Store</NavLink></li>
                                    <li className="nav-item"><NavLink className="nav-link" to="/about" activeClassName="active">About</NavLink></li>
                                </Nav>
                                <LogInOutButton/>
                            </Navbar.Collapse>
                        </Navbar>
                        <AuthModal />
                        <div className="wide-container">
                            <Route exact path="/" component={Home}/>
                            <Route path="/timeline" component={Timeline}/>
                            <Route path="/gallery" component={Gallery}/>
                            <Route path="/about" component={About}/>
                            <Route path="/privacy" component={Privacy}/>
                            <Route path="/terms" component={Terms}/>
                            <Route path="/community-guidelines" component={Community}/>
                            <Route path="/user-lookup" component={UserLookup}/>
                        </div>
                    </div>
                </Router>
            </Provider>
        );
    }
}

export default App;
